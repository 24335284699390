<template>
  <b-col class="analytics-grid">
    <h2 class="py-1">
      Recents
    </h2>
    <b-row>
      <b-col
        v-for="i in 4"
        :key="i"
        cols="3"
        md="6"
        lg="3"
      >
        <b-card
          class="text-center"
          @click="$router.push({ name: 'platform-analytics-view', params: {id: i}})"
        >
          <!-- <b-img
            :src="require('@/assets/images/placeholder/landscape-square.png')"
            fluid
            alt="image"
          /> -->
          <h1 class="text-left pt-2">
            Chart {{ i }}
          </h1>
        </b-card>
      </b-col>
    </b-row>

    <hr>

    <h2 class="py-1">
      All Charts
    </h2>
    <b-row>
      <b-col
        v-for="i in 9"
        :key="i"
        cols="3"
        md="6"
        lg="3"
      >
        <b-card
          class="text-center"
          @click="$router.push({ name: 'platform-analytics-view', params: {id: i}})"
        >
          <!-- <b-img
            :src="require('@/assets/images/placeholder/landscape-square.png')"
            fluid
            alt="image"
          /> -->
          <h1 class="text-left pt-2">
            Chart {{ i }}
          </h1>
        </b-card>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  //
}
</script>

<style>

</style>
